import { render, staticRenderFns } from "./CabinetAdmin.vue?vue&type=template&id=19a48dae&scoped=true"
import script from "./CabinetAdmin.vue?vue&type=script&lang=js"
export * from "./CabinetAdmin.vue?vue&type=script&lang=js"
import style0 from "./CabinetAdmin.vue?vue&type=style&index=0&id=19a48dae&prod&scoped=true&lang=scss"
import style1 from "./CabinetAdmin.vue?vue&type=style&index=1&id=19a48dae&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a48dae",
  null
  
)

export default component.exports